import { createSelector } from "@reduxjs/toolkit";

import { Api } from "../../model";
import { DndEntityModel } from "../../model/dndNodeData";
import { FileNode, FileType, Folder } from "../../model/file";
import { WorkspaceFolder } from "../../model/workspace";
import { ROOT_NODE_ID } from "../designer/constants";
import { RootState } from "../index";
import { GenericState } from "../utils/redoableSliceFactory";

import { selectFolder } from "./selectors";

export interface NodeCount {
  [id: string]: number;
}

const getNodeCounts = (selectedFolder: Folder) => {
  const result: NodeCount = {};
  let folderTotal = 0;

  const nodeCount = (nodes: Array<FileNode>, parentId?: string): number => {
    let total = 0;

    for (const node of nodes) {
      if (node.type === FileType.File) {
        total++;
        folderTotal++;
        continue;
      }

      total = total + nodeCount(node.children, node.id);
    }

    if (parentId !== undefined) {
      result[parentId] = total;
    }

    return total;
  };

  nodeCount(selectedFolder);

  return { folderTotal, nodeCounts: result };
};

type ParentIdentifier = string | number;

function flatten(
  items: Array<FileNode>,
  nodeCount: NodeCount,
  parentId: ParentIdentifier | string = "",
  parent: DndEntityModel | null = null,
  filterFn?: (item: FileNode) => boolean
): Array<DndEntityModel> {
  const usableItems = filterFn
    ? items.filter((node) => filterFn(node))
    : [...items];
  usableItems.sort((a, b) => b.type - a.type);

  const flatTree = usableItems.reduce<Array<DndEntityModel>>(
    (acc, item, index) => {
      const flattenedItem: DndEntityModel = {
        id: item.id,
        text: item.displayName,
        parent: parentId,
        droppable: item.type === FileType.Directory,
        data: {
          ...item,
          count: nodeCount[item.id],
          isLast: usableItems.length === index + 1,
          parent: parent,
        },
      };

      return [
        ...acc,
        flattenedItem,
        ...flatten(
          item.children ?? [],
          nodeCount,
          item.id,
          flattenedItem,
          filterFn
        ),
      ];
    },
    []
  );

  return flatTree;
}

export const selectFolderTreeNodesWithRoot = createSelector(
  selectFolder,
  (state: RootState, folder: WorkspaceFolder) => folder,
  (nodes: Array<FileNode>, folderName): Array<DndEntityModel> => {
    const { folderTotal, nodeCounts } = getNodeCounts(nodes);

    const mapedSchemas = flatten(
      nodes,
      nodeCounts,
      ROOT_NODE_ID,
      null,
      (node) => node.type === FileType.Directory
    );

    return [
      {
        id: ROOT_NODE_ID,
        text: folderName,
        parent: 0,
        droppable: true,
        data: {
          type: FileType.Directory,
          count: folderTotal,
          isLast: true,
          parent: null,
          path: folderName,
        },
      },
      ...mapedSchemas,
    ];
  }
);

export const selectSortedTreeNodesWithRoot = createSelector(
  selectFolder,
  (state: RootState, folder: WorkspaceFolder) => folder,
  (nodes: Array<FileNode>, folderName): Array<DndEntityModel> => {
    const { folderTotal, nodeCounts } = getNodeCounts(nodes);

    return [
      {
        id: ROOT_NODE_ID,
        text: folderName,
        parent: 0,
        droppable: true,
        data: {
          type: FileType.Directory,
          count: folderTotal,
          isLast: true,
          parent: null,
          path: ".",
          children: nodes,
        },
      },
      ...flatten(nodes, nodeCounts, ROOT_NODE_ID),
    ];
  }
);

export const selectFolderTreeFolderNodePath = (
  nodes: Array<FileNode>,
  folderId: string
): string | undefined => {
  if (folderId === ROOT_NODE_ID) return "./";

  const { nodeCounts } = getNodeCounts(nodes);
  const dir = (node: FileNode) => node.type === FileType.Directory;
  const flat = flatten(nodes, nodeCounts, ROOT_NODE_ID, null, dir);
  const result = flat.filter(({ id }) => id === folderId);

  return result[0]?.data?.path;
};

export const selectFolderNodeChildren = (
  nodes: Array<DndEntityModel>,
  folderId: string
): DndEntityModel[] => nodes.filter((data) => data.parent === folderId);

function flattenApis(
  items: GenericState<Api>,
  parentId: ParentIdentifier | string = "",
  parent: DndEntityModel | null = null
): Array<DndEntityModel> {
  const flatTree = items.reduce<Array<DndEntityModel>>((acc, item, index) => {
    const { present } = item;

    const flattenedItem: DndEntityModel = {
      id: present.id,
      text: present.name,
      parent: parentId,
      data: {
        ...item,
        type: FileType.Directory,
        isLast: items.length === index + 1,
        parent: parent,
        predefinedIcon: "Api",
      },
    };

    const uniqueTags: Array<string> = Object.entries(present.paths).reduce<
      Array<string>
    >((uqTags, item) => {
      const [, methods] = item;

      if (methods) {
        Object.entries(methods).forEach(([key, val]) => {
          // @ts-expect-error: typescript is lying
          const { tags = [] } = val;
          tags.forEach((tag: string) => {
            if (tag.trim() && !uqTags.includes(tag)) uqTags.push(tag);
          });
        });
      }

      return uqTags;
    }, []);

    const tagTreeItems = uniqueTags.map((tag, index) => ({
      id: `${present.id}#${tag}`,
      text: tag,
      parent: present.id,
      data: {
        ...item,
        type: FileType.File,
        isLast: uniqueTags.length === index + 1,
        parent: flattenedItem,
        iconColor: "#004783",
        invertIconColor: true,
        predefinedIcon: "Tag",
      },
    }));

    return [...acc, flattenedItem, ...tagTreeItems];
  }, []);

  return flatTree;
}

export const selectApiTreeNodes = createSelector(
  (state: RootState) => state.apis,
  (apis): Array<DndEntityModel> => {
    const flatTree = flattenApis(apis, ROOT_NODE_ID);

    return [
      {
        id: ROOT_NODE_ID,
        text: "apis",
        parent: 0,
        droppable: false,
        data: {
          type: FileType.Directory,
          isLast: true,
          parent: null,
        },
      },
      ...flatTree,
    ];
  }
);
