export type FeatureFlag = {
  description: string;
  active: boolean;
};

/**
 * Interfaces with LocalStorage to initialize and retrieve feature flags with pre-populated defaults.
 * When modifying types, please verify TStore is explicitly typed as Record<"passedFlagName1" | "passedFlagName2" | ..., FeatureFlag>, not Record<string, FeatureFlag>
 * @get Existing (or newly created) feature flag store. Missing defaults are populated.
 */
export class FeatureFlagStore<
  TDefaultStore extends Record<string, FeatureFlag>,
  TStore extends Record<keyof TDefaultStore, FeatureFlag>
> {
  private featureFlags: TStore;

  constructor(private storeId: string, private defaultFlags: TDefaultStore) {
    this.featureFlags = this.loadFeatureFlags();
  }

  public get(): TStore {
    return this.featureFlags;
  }

  private loadFeatureFlags(): TStore {
    const featureFlagBuffer = localStorage.getItem(this.storeId);

    if (featureFlagBuffer) {
      try {
        const featureFlags = this.tryParseFeatureFlagBuffer(featureFlagBuffer);
        return this.populateMissingFeatureFlags(featureFlags);
      } catch (e) {
        console.warn(
          `Failed to parse feature flags, error: ${
            (e as Error).message
          }, flags will be reset to default values`
        );
      }
    }

    return this.setDefaultFeatureFlags();
  }

  private setFeatureFlags(store: Record<string, FeatureFlag>): TStore {
    localStorage.setItem(this.storeId, JSON.stringify(store));
    return store as TStore;
  }

  private setDefaultFeatureFlags(): TStore {
    return this.setFeatureFlags(this.defaultFlags);
  }

  private populateMissingFeatureFlags(existingFlags: TStore): TStore {
    const storeFlags: Record<string, FeatureFlag> = { ...existingFlags };

    Object.entries(this.defaultFlags).forEach(([key, value]) => {
      if (storeFlags[key] === undefined) {
        storeFlags[key] = value;
      }
    });

    this.setFeatureFlags(storeFlags);
    return storeFlags as TStore;
  }

  private tryParseFeatureFlagBuffer(buffer: string): TStore {
    const featureFlagStore = JSON.parse(buffer);

    if (!featureFlagStore) {
      throw new Error("Parsed feature flags with invalid format");
    }

    return { ...this.defaultFlags, ...featureFlagStore };
  }
}
