import { createSelector } from "@reduxjs/toolkit";
import { OpenAPIV3 } from "openapi-types";

import { API_OPERATION_PARTS_DELIMITER } from "../../components/api/api-operation-form/utils/extractApiId";
import { extractApiId } from "../../components/api/api-operation-form/utils/extractApiId";
import { AceApiOperation, Api } from "../../model";
import { ROOT_NODE_ID } from "../designer/constants";
import { GenericState, Redoable } from "../utils/redoableSliceFactory";
import { RootState } from "..";

export const nameSelector = (api: Api): string => api.name;

const selectSelectedStateApi = createSelector(
  (state: RootState) => state.apis,
  (state: RootState) => state.designer.selectedApiTreeEntityId,
  (apis, selectedApiTreeEntityId) => {
    if (selectedApiTreeEntityId.includes("#")) {
      return selectStateApi(apis, extractApiId(selectedApiTreeEntityId));
    }

    return selectStateApi(apis, selectedApiTreeEntityId);
  }
);

const selectStateApi = (
  state: GenericState<Api>,
  baseApiId: string
): Redoable<Api> | undefined =>
  state.find((api) => api.present.id === baseApiId);

export const selectSelectedAPI = createSelector(
  selectSelectedStateApi,
  (api) => api?.present
);

const selectAllApis = createSelector(
  (state: RootState) => state.apis,
  (state: RootState) => state.designer.selectedApiTreeEntityId,
  (apis, selectedApiTreeEntityId) => {
    if (!selectedApiTreeEntityId) {
      return apis.map((api) => api.present);
    }

    const [id] = selectedApiTreeEntityId.split(API_OPERATION_PARTS_DELIMITER);

    if (id === ROOT_NODE_ID) {
      return apis.map((api) => api.present);
    }

    if (id) {
      const selectedApi: Redoable<Api> | undefined = apis.find(
        (api) => api.present.id === id
      );
      return selectedApi ? [selectedApi.present] : [];
    }

    return apis.map((api) => api.present);
  }
);

export const getApiOperationId = (
  path: string,
  method: string,
  apiId: string
): string => `${apiId}#${path}#${method}`;

const getOperationsFromEndpoint = (
  endpoint: OpenAPIV3.PathItemObject,
  path: string,
  apiId: string
) =>
  Object.values(OpenAPIV3.HttpMethods).reduce<AceApiOperation[]>(
    (operations, method) => {
      const operation = endpoint[method];
      if (!operation) return operations;
      const id = getApiOperationId(path, method, apiId);
      return [
        ...operations,
        { path, verb: method, id, ...operation } as AceApiOperation,
      ];
    },
    []
  );

export const getOperationList = (
  paths: OpenAPIV3.PathsObject,
  apiId: string
): AceApiOperation[] =>
  Object.entries(paths).reduce<AceApiOperation[]>(
    (operations, [path, endpoint]) =>
      endpoint
        ? [...operations, ...getOperationsFromEndpoint(endpoint, path, apiId)]
        : operations,
    []
  );

export const selectAllApiOperations = createSelector(
  selectAllApis,
  (state: RootState) => state.designer.selectedApiTreeEntityId,
  (allPis, selectedApiTreeEntityId) => {
    if (!allPis) return [];

    const mergedOperations: AceApiOperation[] = allPis.reduce<
      AceApiOperation[]
    >((acc, api) => [...acc, ...getOperationList(api.paths, api.id)], []);
    const [, tag] = selectedApiTreeEntityId.split(
      API_OPERATION_PARTS_DELIMITER
    );

    if (tag) {
      return mergedOperations.filter((api) => api.tags?.includes(tag));
    }

    return mergedOperations;
  }
);

export const selectSelectedApiOperations = createSelector(
  selectSelectedAPI,
  (api) => (api ? getOperationList(api.paths, api.id) : [])
);

export const selectApiTags = createSelector(
  selectSelectedApiOperations,
  selectSelectedAPI,
  (apiOperations, api) => {
    const operationTags = apiOperations.map((o) => o.tags || []).flat();
    const rootTags = api?.tags?.map((t) => t.name) || [];
    const uniqueTags = Array.from(new Set([...operationTags, ...rootTags]));
    return uniqueTags.map((o) => ({ label: o, value: o }));
  }
);
