import React from "react";
import { useDispatch } from "react-redux";
import RefreshIcon from "@mui/icons-material/Refresh";
import UploadIcon from "@mui/icons-material/Upload";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

import { useAppSelector } from "../../store";
import { openWorkspaceManager } from "../../store/designer/reducers";
import { selectDefaultBranchName } from "../../store/workspaces/selectors";
import WorkspaceIcon from ".././icons/Workspace";
import { useWorkspaceRefresh } from "../utils/useWorkspaceRefresh";

import { ImportWorkspaceDialog } from "./ImportWorkspaceDialog";

const Root = styled("div")(() => ({
  display: "flex",
  height: "20px",
  marginLeft: "110px",
}));

const bottomBarButtonStyles = {
  fontSize: "11px",
  marginLeft: "4px",
  padding: "0 !important",
  color: "white",
  minWidth: "42px !important",
};
const createBranchPlaceholder = "<unknown>";

const WorkspaceSelector: React.FC = () => {
  const [workspaceImportOpen, setWorkspaceImportOpen] = React.useState(false);
  const name = useAppSelector(selectDefaultBranchName);

  const dispatch = useDispatch();

  const { handleRefresh } = useWorkspaceRefresh();

  const handleImportOpen = () => {
    setWorkspaceImportOpen(true);
  };

  const handleImportClose = () => {
    setWorkspaceImportOpen(false);
  };

  return (
    <Root>
      <Button
        onClick={(e) => {
          e.currentTarget.blur();
          dispatch(openWorkspaceManager());
        }}
        sx={{
          fontSize: "11px",
          marginLeft: "4px",
          padding: 0,
          color: "white",
        }}
        data-testid="workspaceSelector"
        startIcon={
          <WorkspaceIcon sx={{ height: "16px", width: "16px", margin: "0" }} />
        }
      >
        {name === undefined ? createBranchPlaceholder : name}
      </Button>
      <Button
        onClick={handleRefresh}
        sx={bottomBarButtonStyles}
        data-testid="refresh-workspace-btn"
        startIcon={
          <RefreshIcon
            sx={{ height: "16px", width: "16px", margin: "0 -3px 0 24px" }}
          />
        }
      >
        Refresh
      </Button>
      <Button
        onClick={handleImportOpen}
        sx={bottomBarButtonStyles}
        data-testid="open-workspace"
        startIcon={
          <UploadIcon
            sx={{ height: "16px", width: "16px", margin: "0 -3px 0 24px" }}
          />
        }
      >
        Import
      </Button>
      {workspaceImportOpen && (
        <ImportWorkspaceDialog
          open={workspaceImportOpen}
          handleClose={handleImportClose}
        />
      )}
    </Root>
  );
};

export default WorkspaceSelector;
