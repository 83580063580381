import { RepositoryDetails } from "@sapiens-digital/ace-designer-common/lib/model/git";
import * as workspacePaths from "@sapiens-digital/ace-designer-common/lib/model/workspacePaths";
import { HttpClient, PromiseFsClient } from "isomorphic-git";
import pathModule from "path";
import { v4 as uuidv4 } from "uuid";

import { FileNode } from "../../model/file";
import {
  ExecuteFlowApiResult,
  ExecuteFlowOptions,
  ExecuteFlowResult,
} from "../../model/flowExecution";
import {
  LocalWorkspace,
  Remote,
  Workspace,
  WorkspaceFolder,
  WorkspaceVersion,
} from "../../model/workspace";
import { readError } from "../../store/utils/readError";
import {
  copyFilesDeep,
  exists,
  isDir,
  mkdir,
  readYaml,
  rmdirDeep,
} from "../fs-utils";
import {
  getRemoteInfo,
  gitClone,
  gitPush,
  initializeGitRepository,
  synchronizeWorkspaceWithRemote,
} from "../git-utils";
import { loadNodes } from "../nodes";
import {
  deserializeId,
  resetRefRegistry,
  upsertDeserializedId,
} from "../references";
import { SettingsManager } from "../settingsManager";
import { createTemporaryFolder } from "../temporaryFiles";
import { container, symbols } from "../";

import { migrateV1EntitiesToV2, MigrationError } from "./migrate";
import {
  loadWorkspaceSettings,
  saveWorkspaceSettings,
} from "./workspaceSettings";

export const path = pathModule.posix || pathModule;

export enum Environment {
  Electron,
  Web,
}

const folderPathMap: Record<WorkspaceFolder, string> = {
  apis: workspacePaths.WORKSPACE_APIS,
  flows: workspacePaths.WORKSPACE_FLOWS,
  schedules: workspacePaths.WORKSPACE_SCHEDULES,
  schemas: workspacePaths.WORKSPACE_API_SCHEMAS,
  virtualSteps: workspacePaths.WORKSPACE_VIRTUAL_STEPS,
  modelFields: workspacePaths.WORKSPACE_API_FIELDS,
  modelHeaders: workspacePaths.WORKSPACE_API_HEADERS,
  modelParameters: workspacePaths.WORKSPACE_API_PARAMETERS,
  modelResponses: workspacePaths.WORKSPACE_API_RESPONSES,
  variables: workspacePaths.WORKSPACE_VARIABLES,
  errorHandlers: workspacePaths.WORKSPACE_ERROR_HANDLERS,
};

export interface GetWorkspaceFS {
  (): PromiseFsClient;
}

export interface GetWorkspaceHttpClient {
  (): HttpClient;
}

export interface GetEnvironment {
  (): Environment;
}

export interface ExecuteFlow {
  (config: ExecuteFlowOptions, repoDetails?: RepositoryDetails): Promise<
    ExecuteFlowResult | ExecuteFlowApiResult
  >;
}

export const getWorkspaceFS: GetWorkspaceFS = () =>
  container.get<GetWorkspaceFS>(symbols.GetWorkspaceFS)();

export const getEnvironment: GetEnvironment = () =>
  container.get<GetEnvironment>(symbols.GetEnvironment)();

export const getWorkspaceHttpClient: GetWorkspaceHttpClient = () =>
  container.get<GetWorkspaceHttpClient>(symbols.GetWorkspaceHttpClient)();

export const executeFlow: ExecuteFlow = async (config, repoDetails) =>
  container.get<ExecuteFlow>(symbols.ExecuteFlow)(config, repoDetails);

function getDefaultWorkspace({
  workspaceName,
  workspaceLocation,
  repositoryRoot,
  version,
}: {
  workspaceName: string;
  workspaceLocation: string;
  repositoryRoot: string;
  version: WorkspaceVersion;
}): Workspace {
  return {
    id: deserializeId("/", workspaceName),
    name: workspaceName,
    location: workspaceLocation,
    repositoryRoot: repositoryRoot,
    flowIds: [],
    flows: [],
    apis: [],
    apiIds: [],
    schedules: [],
    schemas: [],
    virtualSteps: [],
    modelFields: [],
    modelHeaders: [],
    modelParameters: [],
    modelResponses: [],
    variables: [],
    errorHandlers: [],
    version,
  };
}

const initializeWorkspace = async (
  workspaceName: string,
  workspaceLocation: string,
  repositoryRoot: string,
  version: WorkspaceVersion
): Promise<void> => {
  const workspaceRoot = path.join(workspaceLocation, repositoryRoot);

  await mkdir(workspaceRoot);
  await mkdir(path.join(workspaceRoot, workspacePaths.WORKSPACE_FLOWS));
  await mkdir(path.join(workspaceRoot, workspacePaths.WORKSPACE_APIS));
  await mkdir(path.join(workspaceRoot, workspacePaths.WORKSPACE_API_SCHEMAS));
  await mkdir(path.join(workspaceRoot, workspacePaths.WORKSPACE_API_FIELDS));
  await mkdir(path.join(workspaceRoot, workspacePaths.WORKSPACE_API_HEADERS));
  await mkdir(
    path.join(workspaceRoot, workspacePaths.WORKSPACE_API_PARAMETERS)
  );
  await mkdir(path.join(workspaceRoot, workspacePaths.WORKSPACE_API_RESPONSES));
  await mkdir(path.join(workspaceRoot, workspacePaths.WORKSPACE_SCHEDULES));
  await mkdir(path.join(workspaceRoot, workspacePaths.WORKSPACE_API_SCHEMAS));
  await mkdir(
    path.join(workspaceRoot, workspacePaths.WORKSPACE_ERROR_HANDLERS)
  );
  await mkdir(path.join(workspaceRoot, workspacePaths.WORKSPACE_VARIABLES));
  await mkdir(path.join(workspaceRoot, workspacePaths.WORKSPACE_VIRTUAL_STEPS));
};

export const createWorkspace = async (
  workspaceName: string,
  repositoryRoot: string,
  repositoryUrl?: string,
  repositoryToken?: string,
  repositoryDefaultBranch?: string,
  repositoryUsername?: string
): Promise<Workspace> => {
  const workspaceLocation = getWorkspaceLocation(workspaceName);

  if (await exists(workspaceLocation)) {
    throw Error(`Directory ${workspaceLocation} already exists.`);
  }

  await mkdir(workspaceLocation);

  await initializeGitRepository({
    repositoryToken,
    workspaceName,
    repositoryUrl,
    workspaceLocation,
    repositoryDefaultBranch,
    repositoryUsername,
  });

  await initializeWorkspace(
    workspaceName,
    workspaceLocation,
    repositoryRoot,
    WorkspaceVersion.V2
  );

  return loadWorkspace(
    workspaceLocation,
    repositoryRoot,
    WorkspaceVersion.V2,
    workspaceName
  );
};

export function getWorkspaceLocation(workspaceName: string): string {
  return path.join(SettingsManager.getWorkspacesLocation(), workspaceName);
}

/**
 * Retrieve workspace from git and read it
 * @param workspaceName
 * @param repositoryRoot
 * @param repositoryUrl
 * @param repositoryToken
 * @param repositoryUsername
 */
export const openWorkspace = async (
  workspaceName: string,
  repositoryRoot: string,
  repositoryUrl: string,
  repositoryToken: string,
  repositoryUsername?: string
): Promise<Workspace> => {
  const workspaceLocation = getWorkspaceLocation(workspaceName);

  if (await exists(workspaceLocation)) {
    throw Error(`Directory ${workspaceLocation} already exists.`);
  }

  await mkdir(workspaceLocation);
  await gitClone(
    repositoryToken,
    {
      dir: workspaceLocation,
      ref: workspaceName,
      url: repositoryUrl,
    },
    repositoryUsername
  );

  const version = WorkspaceVersion.V2;

  await initializeWorkspace(
    workspaceName,
    workspaceLocation,
    repositoryRoot,
    version
  );

  return loadWorkspace(
    workspaceLocation,
    repositoryRoot,
    version,
    workspaceName
  );
};

/**
 * Retrieve workspace from git and read it
 * @param workspaceName
 * @param repositoryRoot
 * @param repositoryUrl
 * @param repositoryToken
 * @param repositoryUsername
 */
export const updateWorkspace = async (
  workspaceName: string,
  repositoryRoot: string,
  repositoryUrl: string,
  repositoryToken: string,
  repositoryUsername?: string
): Promise<Workspace> => {
  const workspaceLocation = getWorkspaceLocation(workspaceName);

  await synchronizeWorkspaceWithRemote(
    workspaceName,
    workspaceLocation,
    repositoryUrl,
    repositoryToken,
    repositoryUsername
  );

  return loadWorkspace(
    workspaceLocation,
    repositoryRoot,
    WorkspaceVersion.V2,
    workspaceName
  );
};

export const updateFolder = async (
  workspace: Workspace,
  folder: WorkspaceFolder
): Promise<Array<FileNode>> => {
  const root = getContentRoot(workspace, folder);
  const location = getDirPath(workspace, folder);
  return loadNodes(root, location, false);
};

export const getContentRoot = (
  workspace: Pick<Workspace, "location">,
  folder: WorkspaceFolder
): string => path.join(workspace.location, getFolderPath(folder));

async function loadOrGenerateWorkspaceSettings(
  workspaceName: string
): Promise<LocalWorkspace> {
  const settings = await loadWorkspaceSettings();
  const wsSettings = settings.workspaces.find(
    ({ folderName }) => folderName === workspaceName
  );

  if (!wsSettings) {
    const id = deserializeId("/", workspaceName);
    const newSettings = { id, folderName: workspaceName };
    await saveWorkspaceSettings({
      workspaces: [...settings.workspaces, newSettings],
    });
    return newSettings;
  }

  return wsSettings;
}

const WORKSPACE_DEFAULT_NAME = "UNKNOWN";

export const loadWorkspace = async (
  location: string,
  repositoryRoot: string,
  version: WorkspaceVersion,
  workspaceName: string = WORKSPACE_DEFAULT_NAME
): Promise<Workspace> => {
  const { id } = await loadOrGenerateWorkspaceSettings(workspaceName);
  upsertDeserializedId(id, "/", workspaceName);

  const workspace = getDefaultWorkspace({
    workspaceName,
    workspaceLocation: location,
    repositoryRoot,
    version,
  });

  if (WorkspaceVersion.V2 !== version) {
    return workspace;
  }

  return {
    id,
    name: workspace.name,
    location,
    repositoryRoot: workspace.repositoryRoot,
    selectedFlowId: workspace.selectedFlowId,
    flowIds: workspace.flowIds,
    apiIds: workspace.apiIds,
    version,
    flows: await loadNodes(
      getContentRoot(workspace, "flows"),
      path.join(location, repositoryRoot, workspacePaths.WORKSPACE_FLOWS)
    ),
    apis: await loadNodes(
      getContentRoot(workspace, "apis"),
      path.join(location, repositoryRoot, workspacePaths.WORKSPACE_APIS),
      true
    ),
    schedules: await loadNodes(
      getContentRoot(workspace, "schedules"),
      path.join(location, repositoryRoot, workspacePaths.WORKSPACE_SCHEDULES)
    ),
    schemas: await loadNodes(
      getContentRoot(workspace, "schemas"),
      path.join(location, repositoryRoot, workspacePaths.WORKSPACE_API_SCHEMAS)
    ),
    virtualSteps: await loadNodes(
      getContentRoot(workspace, "virtualSteps"),
      path.join(
        location,
        repositoryRoot,
        workspacePaths.WORKSPACE_VIRTUAL_STEPS
      )
    ),
    modelFields: await loadNodes(
      getContentRoot(workspace, "modelFields"),
      path.join(location, repositoryRoot, workspacePaths.WORKSPACE_API_FIELDS)
    ),
    modelHeaders: await loadNodes(
      getContentRoot(workspace, "modelHeaders"),
      path.join(location, repositoryRoot, workspacePaths.WORKSPACE_API_HEADERS)
    ),
    modelParameters: await loadNodes(
      getContentRoot(workspace, "modelParameters"),
      path.join(
        location,
        repositoryRoot,
        workspacePaths.WORKSPACE_API_PARAMETERS
      )
    ),
    modelResponses: await loadNodes(
      getContentRoot(workspace, "modelResponses"),
      path.join(
        location,
        repositoryRoot,
        workspacePaths.WORKSPACE_API_RESPONSES
      )
    ),
    variables: await loadNodes(
      getContentRoot(workspace, "variables"),
      path.join(location, repositoryRoot, workspacePaths.WORKSPACE_VARIABLES)
    ),
    errorHandlers: await loadNodes(
      getContentRoot(workspace, "errorHandlers"),
      path.join(
        location,
        repositoryRoot,
        workspacePaths.WORKSPACE_ERROR_HANDLERS
      )
    ),
  };
};

const isV2Workspace = async (
  location: string,
  repositoryRoot: string
): Promise<boolean> => isDir(path.join(location, repositoryRoot));

export const validateWorkspace = async (
  location: string,
  repositoryRoot: string
): Promise<WorkspaceVersion> => {
  if (!(await exists(location)) || !(await isDir(location))) {
    return WorkspaceVersion.INVALID;
  }

  if (await isV2Workspace(location, repositoryRoot)) {
    return WorkspaceVersion.V2;
  }

  return WorkspaceVersion.UNKNOWN;
};

export const loadWorkspaces = async (
  repositoryRoot: string
): Promise<Array<Workspace>> => {
  const workspacesLocation = SettingsManager.getWorkspacesLocation();
  const fs = getWorkspaceFS();
  const result: Array<Workspace> = [];

  if (
    !(await exists(workspacesLocation)) ||
    !(await isDir(workspacesLocation))
  ) {
    return result;
  }

  resetRefRegistry();
  const allFiles: string[] = await fs.promises.readdir(
    path.join(workspacesLocation)
  );

  const hiddenFolderPrefix = ".";
  const omitHiddenFolders = (file: string) =>
    !path.basename(file).startsWith(hiddenFolderPrefix);
  const files = allFiles.filter(omitHiddenFolders);

  // TODO: do not load all files for all workspaces
  for (const file of files) {
    const version = await validateWorkspace(
      path.join(workspacesLocation, file),
      repositoryRoot
    );

    if (WorkspaceVersion.INVALID !== version) {
      result.push(
        await loadWorkspace(
          path.join(workspacesLocation, file),
          repositoryRoot,
          version,
          file
        )
      );
    }
  }

  await removeObsoleteWorkspaceSettings(result);
  return result;
};

const removeObsoleteWorkspaceSettings = async (
  workspaces: Workspace[]
): Promise<void> => {
  const loadedWorkspaces = workspaces.map(({ name }) => name);
  const { workspaces: currentSettings } = await loadWorkspaceSettings();
  const omitNonLocalWorkspaces = currentSettings.filter((ws) =>
    loadedWorkspaces.includes(ws.folderName)
  );
  await saveWorkspaceSettings({ workspaces: omitNonLocalWorkspaces });
};

export const loadRemotes = async (
  repositoryUrl: string,
  repositoryToken: string,
  repositoryUsername?: string
): Promise<Array<Remote>> => {
  const data = await getRemoteInfo(
    repositoryUrl,
    repositoryToken,
    repositoryUsername
  );

  if (data.refs === undefined || data.refs.heads === undefined) {
    return [];
  }

  return Object.keys(data.refs.heads).map((item) => ({
    id: item,
    name: item,
  }));
};

const rmdir = async (location: string) => {
  const fs = getWorkspaceFS();

  if (await isDir(location)) {
    const nodes = (await fs.promises.readdir(location)) as Array<string>;
    const promises = nodes.map((node) => {
      const newLocation = path.join(location, node);
      return rmdir(newLocation);
    });
    await Promise.all(promises);
    await fs.promises.rmdir(location);
  } else {
    await fs.promises.unlink(location);
  }
};

export const deleteWorkspace = async (location: string): Promise<void> => {
  await rmdir(location);
};

const cloneWorkspace = async (
  repositoryUrl: string,
  repositoryToken: string,
  location: string,
  workspaceName: string,
  repositoryUsername?: string
): Promise<boolean> => {
  try {
    await gitClone(
      repositoryToken,
      {
        dir: location,
        ref: workspaceName,
        url: repositoryUrl,
      },
      repositoryUsername
    );
    return true;
  } catch (err) {
    console.error(
      `Failed to create temp clone. Error: ${readError(err, "message")}`
    );
    return false;
  }
};

export const deleteRemote = async (
  workspace: Remote,
  repositoryUrl: string,
  repositoryToken: string,
  repositoryUsername?: string
): Promise<void> => {
  const workspaceLocation = getWorkspaceLocation(workspace.name);

  let isTempCloneCreated = false;
  const tempLocation = "/" + uuidv4();

  // TODO: check
  // temp clone branch because git.push needs config file to read
  if (!(await isDir(workspaceLocation))) {
    isTempCloneCreated = await cloneWorkspace(
      repositoryUrl,
      repositoryToken,
      tempLocation,
      workspace.name,
      repositoryUsername
    );
  }

  const result = await gitPush(
    repositoryToken,
    {
      dir: isTempCloneCreated ? tempLocation : workspaceLocation,
      ref: workspace.name,
      delete: true,
      url: repositoryUrl,
    },
    repositoryUsername
  );

  if (isTempCloneCreated) {
    await rmdir(tempLocation);
  }

  if (!result.ok) {
    throw Error(`Failed to delete remote branch. Error: ${result.error}`);
  }
};

export const importWorkspace = async (
  workspaceLocation: string,
  repositoryRoot: string,
  sourceWorkspaceLocation: string,
  sourceRepositoryRoot: string,
  overwriteExisting?: boolean,
  migrationWorkspaceRoot?: string
): Promise<{ errors: MigrationError[] }> => {
  const workspacesLocation = SettingsManager.getWorkspacesLocation();
  const version = await validateWorkspace(
    sourceWorkspaceLocation,
    sourceRepositoryRoot
  );

  let sourceRepositoryLocation = path.join(
    sourceWorkspaceLocation,
    sourceRepositoryRoot
  );
  const repositoryLocation = path.join(workspaceLocation, repositoryRoot);

  const errors: MigrationError[] = [];
  const needsMigration = version !== WorkspaceVersion.V2;

  if (needsMigration) {
    sourceRepositoryLocation = path.join(
      await createTemporaryFolder(workspacesLocation),
      repositoryRoot
    );

    // for now, treat NON-V2 as V1
    const migrationErrors = await migrateV1EntitiesToV2(
      path.join(sourceWorkspaceLocation, migrationWorkspaceRoot ?? ""),
      sourceRepositoryLocation,
      repositoryLocation,
      overwriteExisting
    );

    if (migrationErrors.length) {
      errors.push(...migrationErrors);
      console.error(...migrationErrors);
    }
  }

  if (overwriteExisting) {
    await rmdirDeep(repositoryLocation, { skipLocationRoot: true });
  }

  await copyFilesDeep(sourceRepositoryLocation, repositoryLocation);

  return { errors };
};

/**
 * Retrieves one of workspace folder's path
 * @returns one of workspace folder's path (file system path)
 */
export const getDirPath = (
  workspace: Workspace,
  folder: WorkspaceFolder
): string =>
  path.join(
    workspace.location,
    workspace.repositoryRoot,
    getFolderPath(folder)
  );

export const getFolderPath = (folder: WorkspaceFolder): string => {
  const folderPath = folderPathMap[folder];

  if (folderPath === undefined) {
    throw new Error(`Can not get folder path for ${folder}`);
  }

  return folderPath;
};

/**
 * Reads file from the workspace under specific dir
 */
export const readFileFromWorksapce = (
  workspace: Workspace,
  folder: WorkspaceFolder,
  filePath: string
): unknown => {
  const dir = getDirPath(workspace, folder);
  const absPath = path.join(dir, filePath);
  return readYaml(absPath);
};
