import { FeatureFlagStore } from "./featureFlagsStore";

const FEATURE_FLAG_KEY = "FEATURE_FLAGS";
const DEFAULT_FEATURE_FLAGS = {
  switchStep: {
    description: "Enable SWITCH step in steps list",
    active: false,
  },
  virtualStep: {
    description: "Enable VIRTUAL STEP entity",
    active: false,
  },
};

export const FEATURE_FLAGS = new FeatureFlagStore(
  FEATURE_FLAG_KEY,
  DEFAULT_FEATURE_FLAGS
).get();
