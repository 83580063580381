import { Draft } from "@reduxjs/toolkit";

import { Tabbable, tabbableEntityConfig } from "../../model/workspace.tabs";
import { RootState } from "../index";
import { reorder } from "../utils/reorder";
import { selectSelectedWorkspace } from "../workspaces/selectors";

export const reorderOpenTabs = (
  state: Draft<RootState>,
  params: { fromIdx: number; toIdx: number },
  tabbableEntity: Tabbable
): void => {
  const workspace = selectSelectedWorkspace(state);

  if (!workspace) {
    return;
  }

  const { openIdsKey } = tabbableEntityConfig[tabbableEntity];
  const { fromIdx, toIdx } = params;
  state.workspaces.entities[workspace.id]![openIdsKey] = reorder(
    workspace[openIdsKey],
    fromIdx,
    toIdx
  );
};

export const openTab = (
  state: RootState,
  id: string,
  tabbableEntity: Tabbable
): void => {
  const workspace = selectSelectedWorkspace(state);

  if (!workspace) {
    return;
  }

  const { selectedIdKey, openIdsKey } = tabbableEntityConfig[tabbableEntity];

  state.workspaces.entities[workspace.id]![selectedIdKey] = id;

  if (!workspace[openIdsKey].includes(id)) {
    state.workspaces.entities[workspace.id]![openIdsKey].push(id);
  }
};

export function closeTab(
  state: RootState,
  id: string,
  tabbableEntity: Tabbable
): void {
  const workspace = selectSelectedWorkspace(state);

  if (!workspace) {
    return;
  }

  const { selectedIdKey, openIdsKey } = tabbableEntityConfig[tabbableEntity];

  const otherIds = workspace[openIdsKey].filter((entityId) => entityId !== id);
  state.workspaces.entities[workspace.id]![openIdsKey] = otherIds;

  // if current tab is being closed = remove it from selected
  if (workspace[selectedIdKey] === id) {
    state.workspaces.entities[workspace.id]![selectedIdKey] =
      otherIds[otherIds.length - 1];
  }
}
