import { AceApiOperation, Api, SerializedApi } from "../model";
import { Workspace } from "../model/workspace";
import { nameSelector } from "../store/apis/selectors";

import { updateFlowUsageByEntity } from "./indexing/flowIndexHelper";
import { removeUsageOfEntity } from "./indexing/indexer";
import { DeleteEntity, GetEntity, SaveEntity } from "./entityService.types";
import { deleteFile, getFileDisplayName, readFile, saveFile } from "./fs-utils";

/**
 * Obtains the api from workspace
 * @param id api id
 * @param workspace workspace
 * @returns api stored in file system
 */
export const getApi: GetEntity<Api> = async (id, workspace) => {
  try {
    const fileApi = await readFile(id, workspace, "apis");
    const fileName = getFileDisplayName(id, workspace, "apis");
    return mapApi(id, fileName, fileApi as SerializedApi);
  } catch (e) {
    console.error(e);
    throw new Error(`Api with the id "${id}" can not be retrieved`);
  }
};

/**
 * Saves api to workspace
 * @param api dynamic api to save
 * @param workspace workspace
 * @returns saved api
 */
export const saveApi: SaveEntity<Api> = async (api, workspace) => {
  const name = nameSelector(api);

  try {
    const cleanApi = mapSerializedApi(api);
    await saveFile(api.id, workspace, "apis", cleanApi, name);
    return api;
  } catch (e) {
    console.error(e);
    throw new Error(`Api "${name}" can not be saved`);
  }
};

/**
 * Deletes api from workspace by id
 * @param id api id
 * @param workspace workspace
 */
export const deleteApi: DeleteEntity = async (id, workspace) => {
  try {
    await deleteFile(id, workspace, "apis");
  } catch (e) {
    console.error(e);
    throw new Error(`Api with the id "${id}" can not be deleted`);
  }
};

export const mapApi = (
  id: string,
  fileName: string,
  fileApi: SerializedApi
): Api => ({
  ...fileApi,
  id,
  name: fileName,
});

export const mapSerializedApi = (api: Api): SerializedApi => {
  const { id, ...rest } = api;
  return rest;
};

export const addFlowUsageForApiOperation = (
  api: AceApiOperation,
  workspace: Workspace,
  previousId?: string
): void => {
  if (previousId) {
    removeUsageOfEntity(previousId, "apis");
  }

  const flowName = api["x-ace-flow"];

  if (flowName) {
    updateFlowUsageByEntity(api.id, flowName, "apis", workspace);
  }
};

export const initApiReferences = async (
  allOperations: AceApiOperation[],
  workspace: Workspace
): Promise<void> => {
  allOperations.forEach((api) => addFlowUsageForApiOperation(api, workspace));
};
