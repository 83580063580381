import { v4 as uuid } from "uuid";

import {
  EnvVariable,
  EnvVariables,
  SerializedEnvVariable,
  SerializedEnvVariables,
} from "../model/variable";

import { GetEntity, SaveEntity } from "./entityService.types";
import { getFileDisplayName, readFile, saveFile } from "./fs-utils";

/**
 * Obtains the environment variables from workspace
 * @param id variable id
 * @param workspace workspace
 * @returns environment variable stored in file system
 */

export const getVariables: GetEntity<EnvVariables> = async (id, workspace) => {
  try {
    const fileVariables = await readFile(id, workspace, "variables");
    const fileName = getFileDisplayName(id, workspace, "variables");
    return mapVariables(id, fileName, fileVariables as SerializedEnvVariables);
  } catch (e) {
    console.error(e);
    throw new Error(`Variable with the id "${id}" can not be retrieved`);
  }
};

/**
 * Saves Variable to workspace
 * @param variable Environment Variables to save
 * @param workspace workspace
 * @returns saved Serialized Environment Variable
 */
export const saveEnvVariables: SaveEntity<EnvVariables> = async (
  variable,
  workspace
) => {
  try {
    const serializedVariable = mapSerializedVariable(variable);
    await saveFile(
      variable.id,
      workspace,
      "variables",
      serializedVariable,
      variable.name
    );
    return variable;
  } catch (e) {
    console.error(e);
    throw new Error(`Variable env can not be saved`);
  }
};

export const mapSerializedVariable = (
  variable: EnvVariables
): SerializedEnvVariables => {
  const { id, ...rest } = variable;
  const variables: SerializedEnvVariable[] = rest.variables.map((v) => ({
    name: v.name,
    value: v.value,
  }));
  return { variables };
};

const mapIdToVariables = (
  variableFile: SerializedEnvVariables
): EnvVariable[] =>
  variableFile.variables?.map((variable) => ({
    id: uuid(),
    name: variable.name,
    value: variable.value,
  }));

export const mapVariables = (
  id: string,
  fileName: string,
  fileVariables: SerializedEnvVariables
): EnvVariables => {
  const variables = mapIdToVariables(fileVariables);
  return {
    variables: variables || [],
    id,
    name: fileName,
  };
};
