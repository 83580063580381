import { UiSchema } from "react-jsonschema-form";
import { JSONSchema6, JSONSchema6Definition } from "json-schema";
import isPlainObject from "lodash/isPlainObject";

import {
  REGX_FOR_VALID_STRING_EXPRESSION,
  SerializedVirtualStep,
  StepSchema,
  VirtualStep,
} from "../model";
import { Schema } from "../model/schemas";
import { Workspace, WorkspaceFolder } from "../model/workspace";
import { nameSelector } from "../store/virtual-steps/selectors";

import {
  DeleteEntity,
  GetEntity,
  MoveEntity,
  SaveEntity,
} from "./entityService.types";
import {
  deleteFile,
  getFileDisplayName,
  moveFile,
  readFile,
  saveFile,
} from "./fs-utils";
import { getDeserializedId } from "./references";
import { getContentRoot } from "./workspace";

const folder: WorkspaceFolder = "virtualSteps";

export const getVirtualStep: GetEntity<VirtualStep> = async (id, workspace) => {
  try {
    const file = await readFile(id, workspace, folder);
    const fileName = getFileDisplayName(id, workspace, "virtualSteps");
    return deserializeVirtualStep(id, file as SerializedVirtualStep, fileName);
  } catch (e) {
    console.error(e);
    throw new Error(`Virtual step with the id "${id}" can not be retrieved`);
  }
};

export const saveVirtualStep: SaveEntity<VirtualStep> = async (
  virtualStep,
  workspace,
  targetPath
) => {
  const name = nameSelector(virtualStep);

  try {
    const serialized = serializeVirtualStep(virtualStep);
    await saveFile(
      virtualStep.id,
      workspace,
      "virtualSteps",
      serialized,
      name,
      targetPath
    );
    return virtualStep;
  } catch (e) {
    console.error(e);
    throw new Error(`Virtual step "${name}" can not be saved`);
  }
};

export const deleteVirtualStep: DeleteEntity = async (id, workspace) => {
  try {
    await deleteFile(id, workspace, "virtualSteps");
  } catch (e) {
    console.error(e);
    throw new Error(`Virtual step with the id "${id}" can not be deleted`);
  }
};

export const moveVirtualStep: MoveEntity<VirtualStep> = async (
  id,
  workspace,
  targetPath
) => {
  try {
    await moveFile(id, workspace, "virtualSteps", targetPath);
  } catch (e) {
    console.error(e);
    throw new Error(`Virtual step with the id "${id}" can not be moved`);
  }
};

export const deserializeVirtualStep = (
  id: string,
  file: SerializedVirtualStep,
  fileName: string
): VirtualStep => ({
  id,
  fileName,
  ...file,
});

export const serializeVirtualStep = ({
  id,
  fileName,
  ...file
}: VirtualStep): SerializedVirtualStep => file;

export const getVirtualStepId = async (
  path: string,
  workspace: Workspace
): Promise<string> => {
  const id = getDeserializedId(getContentRoot(workspace, "virtualSteps"), path);

  if (id === undefined) {
    throw new Error(
      `Virtual step with the path "${path}" can not be retrieved`
    );
  }

  return id;
};

export const createVirtualStepInstanceSchema = (
  virtualStep: VirtualStep,
  virtualStepSchema: Schema | undefined
): StepSchema => {
  const { displayName, inputSchemaUi } = virtualStep;

  const configBaseline: JSONSchema6Definition = {
    title: "Configuration",
    type: "object",
  };

  const schemaFile = virtualStepSchema?.file;

  const configField: { [k: string]: JSONSchema6Definition } =
    schemaFile && isPlainObject(schemaFile)
      ? { config: { ...(schemaFile as JSONSchema6), ...configBaseline } }
      : {};

  const fullJsonSchema: JSONSchema6 = {
    title: displayName,
    type: "object",
    required: ["targetPath"],
    properties: {
      name: {
        type: "string",
        default: displayName,
      },
      stepType: {
        type: "string",
        title: "stepType",
      },
      description: {
        type: "string",
        title: "Step Description",
      },
      condition: {
        type: "string",
        title:
          "An optional condition on the step. If condition evalutes to false, step will be skipped. (e.g {{input.age>10}})",
        pattern: REGX_FOR_VALID_STRING_EXPRESSION,
      },
      targetPath: {
        type: "string",
        title: "Target path",
      },
      ...configField,
    },
  };

  const fullUiSchema: UiSchema = {
    stepType: {
      "ui:widget": "changeStepTypeWidget",
    },
    description: {
      "ui:widget": "textarea",
    },
    "ui:order": [
      "name",
      "stepType",
      "description",
      "condition",
      "targetPath",
      "config",
      "*",
    ],
    config: inputSchemaUi,
  };

  return {
    stepType: displayName,
    jsonSchema: fullJsonSchema,
    uiSchema: fullUiSchema,
  };
};
