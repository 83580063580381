export const HEADER_REPOSITORY = "ace-repository";
export const HEADER_TOKEN = "ace-token";
export const HEADER_BRANCH = "ace-branch";
export const HEADER_AUTHORIZATION = "ace-authorization";
export const HEADER_COMMIT = "ace-commit";
export const HEADER_USERNAME = "ace-username";

export interface AceHeaders {
  [HEADER_REPOSITORY]: string;
  [HEADER_TOKEN]: string;
  [HEADER_BRANCH]: string;
  [HEADER_AUTHORIZATION]: string;
  [HEADER_COMMIT]?: string;
  [HEADER_USERNAME]?: string;
}
