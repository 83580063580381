import React, { Suspense } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import { Navigate } from "react-router-dom";
import {
  CssBaseline,
  StyledEngineProvider,
  Theme,
  ThemeProvider,
} from "@mui/material";
import { SnackbarProvider } from "notistack";

import WorkspaceManager from "./workspace/WorkspaceManager";
import { aceTheme } from "./ace-theme";
import ConflictResolutionDialog from "./ConflictResolutionDialog";
import DesignerSettings from "./DesignerSettings";
import ErrorBoundary from "./ErrorBoundary";
import LayoutLoader from "./LayoutLoader";
import MainLayout from "./MainLayout";
import NotificationProvider from "./NotificationProvider";

export const FLOWS_PATH = "flows";
export const FLOW_EDITOR_PATH = "edit";
export const SCHEDULER_PATH = "scheduler";
export const APIS_PATH = "dynamic-apis";

const FlowView = React.lazy(() => import("../components/flow/Flows"));
const DynamicApisView = React.lazy(() => import("../components/api/APIs"));
const SchedulerView = React.lazy(
  () => import("../components/scheduler/Scheduler")
);
const SchemaView = React.lazy(() => import("../components/schema/Schema"));

const VirtualStepsView = React.lazy(
  () => import("./virtual-step/VirtualSteps")
);

const ErrorHandlerView = React.lazy(
  () => import("../components/error-handler/ErrorHandler")
);
const EnvVariable = React.lazy(() => import("./variables/EnvVariable"));

interface DesignerProps {
  logo: string;
  theme?: Theme;
}

export const Designer: React.FC<DesignerProps> = (props: DesignerProps) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={props.theme ? props.theme : aceTheme}>
      <NotificationProvider>
        <SnackbarProvider maxSnack={5}>
          <CssBaseline />
          <DesignerSettings />
          <WorkspaceManager />
          <ConflictResolutionDialog />
          <HashRouter>
            <MainLayout logo={props.logo}>
              <Suspense fallback={<LayoutLoader />}>
                <Routes>
                  <Route
                    path="/flows/*"
                    element={
                      <ErrorBoundary>
                        <FlowView />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="/dynamic-apis"
                    element={
                      <ErrorBoundary>
                        <DynamicApisView />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="/scheduler"
                    element={
                      <ErrorBoundary>
                        <SchedulerView />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="/schemas"
                    element={
                      <ErrorBoundary>
                        <SchemaView />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="/virtual-steps"
                    element={
                      <ErrorBoundary>
                        <VirtualStepsView />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="/error-handlers"
                    element={
                      <ErrorBoundary>
                        <ErrorHandlerView />
                      </ErrorBoundary>
                    }
                  />
                  <Route path="/" element={<Navigate replace to="/flows" />} />
                  <Route
                    path="/env-variables"
                    element={
                      <ErrorBoundary>
                        <EnvVariable />
                      </ErrorBoundary>
                    }
                  />
                </Routes>
              </Suspense>
            </MainLayout>
          </HashRouter>
        </SnackbarProvider>
      </NotificationProvider>
    </ThemeProvider>
  </StyledEngineProvider>
);

export default Designer;
