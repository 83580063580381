import React, { useState } from "react";
import { useForm } from "react-hook-form";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import UploadIcon from "@mui/icons-material/Upload";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import Tab from "@mui/material/Tab";

import { NotificationOptionalKey } from "../../model/notification";
import { GitDetails } from "../../model/workspace";
import { useAppDispatch } from "../../store";
import {
  addNotification,
  pullGitRepositoryToTemporaryDirectory,
  unzipToTemporaryDirectory,
} from "../../store/designer/actions";
import { readError } from "../../store/utils/readError";
import { importAndPushWorkspaceAction } from "../../store/workspaces/actions";
import ButtonComponent from "../ButtonComponent";
import { ConfirmationDialog } from "../dialogs";

import ImportWorkspaceGitFormFields from "./ImportWorkspaceGitFormFields";

const ZIP_TAB = "ZIP";

const GIT_TAB = "GIT";

const NOTIFY_IMPORT_PARTIAL_SUCCESS: NotificationOptionalKey = {
  message:
    "Encountered errors during import, workspace was imported partially.",
  variant: "warning",
};
const NOTIFY_IMPORT_SUCCESS: NotificationOptionalKey = {
  message: "Workspace imported successfully.",
  variant: "success",
};

const ERR_MSG_BAD_FORMAT = "Selected file must have zip format and extension.";

interface ImportWorkspaceDialogProps {
  open: boolean;
  handleClose: () => void;
}

export const ImportWorkspaceDialog: React.FC<ImportWorkspaceDialogProps> = (
  props: ImportWorkspaceDialogProps
) => {
  const dispatch = useAppDispatch();
  const { open, handleClose } = props;
  const [workspaceUploaded, setWorkspaceUploaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [overwriteExisting, setOverwriteExisting] = useState(false);
  const [tab, setTab] = useState(ZIP_TAB);
  const [disableImport, setDisableImport] = useState(true);
  const gitForm = useForm<GitDetails>({
    mode: "onChange",
    shouldFocusError: true,
  });
  const {
    formState: { isValid: validGitDetails },
  } = gitForm;
  const gitDetails = gitForm.getValues();

  const handleSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputFile = event.target.files?.[0];

    setSelectedFile(inputFile);
    setWorkspaceUploaded(true);
    setDisableImport(false);
  };

  const handleImportWorkspace = async () => {
    setLoading(true);
    let sourceWorkspaceLocation;
    let migrationRepositoryRoot = "";
    const sourceWorkspaceRoot = "src/ace";
    try {
      switch (tab) {
        case ZIP_TAB: {
          if (!selectedFile?.name.endsWith(".zip")) {
            throw new Error(ERR_MSG_BAD_FORMAT);
          }
          const zipData = Buffer.from(await selectedFile.arrayBuffer());
          sourceWorkspaceLocation = await dispatch(
            unzipToTemporaryDirectory({ zipData })
          ).unwrap();
          break;
        }
        case GIT_TAB: {
          migrationRepositoryRoot = gitDetails.sourcePath ?? "";
          sourceWorkspaceLocation = await dispatch(
            pullGitRepositoryToTemporaryDirectory({ gitData: gitDetails })
          ).unwrap();
          break;
        }
      }
    } catch (e) {
      console.warn(e);
      dispatch(addNotification({ message: readError(e), variant: "error" }));
    }
    try {
      if (sourceWorkspaceLocation) {
        const { errors } = await dispatch(
          importAndPushWorkspaceAction({
            sourceWorkspaceLocation,
            sourceWorkspaceRoot,
            overwriteExisting,
            migrationRepositoryRoot,
          })
        ).unwrap();

        const notification = errors.length
          ? NOTIFY_IMPORT_PARTIAL_SUCCESS
          : NOTIFY_IMPORT_SUCCESS;

        dispatch(addNotification(notification));
      }
    } catch (e) {
      console.warn(e);
      dispatch(addNotification({ message: readError(e), variant: "error" }));
    }
    handleClose();
  };

  const closeLoaded = () => !loading && handleClose();

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
    switch (newValue) {
      case ZIP_TAB:
        setDisableImport(!workspaceUploaded);
        break;
      case GIT_TAB:
        setDisableImport(!validGitDetails);
        break;
    }
  };

  return (
    <ConfirmationDialog
      open={open}
      title={workspaceUploaded ? "Import workspace" : "Upload workspace"}
      loading={loading}
      handleClose={closeLoaded}
      data-testid="import-workspace-dialog"
      renderCancelButton={(defaultProps) => (
        <ButtonComponent
          {...defaultProps}
          disabled={loading}
          onClick={closeLoaded}
        />
      )}
      renderOkButton={(defaultProps) => (
        <ButtonComponent
          {...defaultProps}
          text="import"
          disabled={tab === GIT_TAB ? !validGitDetails : disableImport}
          startIcon={<UploadIcon />}
          onClick={handleImportWorkspace}
        />
      )}
    >
      <TabContext value={tab}>
        <TabList onChange={handleTabChange} aria-label="basic tabs example">
          <Tab label="ZIP" value={ZIP_TAB} data-testid="zip-tab" />
          <Tab label="GIT" value={GIT_TAB} data-testid="git-tab" />
        </TabList>
        <TabPanel value={ZIP_TAB}>
          <Typography sx={{ marginBottom: "24px", paddingTop: "5px" }}>
            {!workspaceUploaded
              ? "Click the Upload ZIP button to upload a workspace zip file from your computer for importing into your workspace"
              : "Successfully uploaded a zip file. Click on Import button to load it into your workspace"}
          </Typography>
          <FormGroup>
            <ButtonComponent
              variant="contained"
              startIcon={<FolderZipIcon />}
              disabled={workspaceUploaded}
            >
              <input
                type="file"
                name="file"
                accept=".zip"
                data-testid="file-upload"
                style={{
                  width: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  cursor: "pointer",
                  opacity: 0,
                }}
                onChange={handleSelectFile}
              />
              {workspaceUploaded ? (
                <Box sx={{ textTransform: "none" }}>
                  Uploaded:{" "}
                  <strong data-testid="file-name">{selectedFile?.name}</strong>
                </Box>
              ) : (
                "Upload ZIP"
              )}
            </ButtonComponent>
            <FormControlLabel
              value={overwriteExisting}
              onChange={() => setOverwriteExisting(!overwriteExisting)}
              sx={{ marginTop: "16px" }}
              control={<Checkbox color="secondary" />}
              label="Delete existing data"
              disabled={loading}
            />
          </FormGroup>
        </TabPanel>
        <TabPanel value={GIT_TAB}>
          <Typography sx={{ marginBottom: "24px", paddingTop: "5px" }}>
            Provide the necessary information to import your workspace
          </Typography>
          <ImportWorkspaceGitFormFields form={gitForm} />
          <FormControlLabel
            value={overwriteExisting}
            onChange={() => setOverwriteExisting(!overwriteExisting)}
            sx={{ marginTop: "16px" }}
            control={<Checkbox color="secondary" />}
            label="Delete existing data"
            disabled={loading}
          />
        </TabPanel>
      </TabContext>
    </ConfirmationDialog>
  );
};
