import { Designer, TabBaseUiConfig } from "../../model/designer";

export const setUiData = (
  state: Designer,
  args: { flowId?: string; schedulerId?: string; stepId?: string },
  data: Partial<TabBaseUiConfig>
): void => {
  const { flowId, schedulerId, stepId } = args;

  if (stepId) {
    state.stepUi[stepId] = {
      ...state.stepUi[stepId],
      ...data,
    };
    return;
  }

  if (flowId) {
    state.flowUi[flowId] = {
      ...state.flowUi[flowId],
      ...data,
    };
    return;
  }

  if (schedulerId) {
    state.schedulerUi[schedulerId] = {
      ...state.schedulerUi[schedulerId],
      ...data,
    };
  }
};

export type ToggableStateNames =
  | "expandedFlowsFolderIds"
  | "expandedSchemasFolderIds"
  | "expandedApiFolderIds"
  | "expandedVirtualStepsFolderIds";

export const toggleEntityFolder = (
  state: Designer,
  entityId: string,
  stateName: ToggableStateNames
): void => {
  if (state[stateName].includes(entityId)) {
    state[stateName] = state[stateName].filter((id) => id !== entityId);
    return;
  }

  state[stateName] = [...state[stateName], entityId];
};
